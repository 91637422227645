import en from "./en.json";
import es from "./es.json";
import de from "./de.json";
import it from "./it.json";

const locales = {
  en,
  es,
  de,
  it,
};

export default locales;
